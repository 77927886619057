




































































import Vue from 'vue';
import AdminTemplateTextService from '@/services/admin/AdminTemplateTextService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import { MetaInfo } from 'vue-meta';
import { validationMixin } from 'vuelidate';
import CreateCard from '@/components/forms/CreateCard.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ToastMessage from '@/models/ToastMessage';

const validations = {
  templateText: {
    templateTextType: {},
  },
};

class TemplateTextIndexDto {
  id!: string;
  name!: string;
  updatedAt!: Date;
  textType!: string;
  variableType!: string;
  templateTextType = '';
}

export default Vue.extend({
  components: { FormatedTimestamp, DataTable, CreateCard, ValidatedSelect },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminTemplateTextIndex.meta.title').toString(),
    };
  },
  validations,
  mixins: [validationMixin],
  data() {
    let templateText = new TemplateTextIndexDto();
    return {
      templateTexts: [] as TemplateTextIndexDto[],
      templateText: templateText,
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'name', asc: false },
      currentPage: 1,
      loading: true,
      ready: false,
      message: '',
      availableTemplateTextTypes: [] as string[],
      tableFilter: {
        external: false,
        lazy: false,
        label: this.$t('components.DataTable.table-filter-label'),
        placeholder: this.$t('components.DataTable.table-filter-placeholder'),
      },
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  computed: {
    availableTemplateTextTypeOptions(): { value: string; label: string }[] {
      let options = this.availableTemplateTextTypes.map((type) => {
        return { value: type, label: type };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      options = [{ value: '', label: '' }, ...options];
      return options;
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminTemplateTextIndex.breadcrumb.last' },
      ]);
    },

    onDelete(templateTextId: string) {
      return AdminTemplateTextService.delete(templateTextId).then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.delete_successful').toString(), 'bg-success')
          );
          this.loadData();
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.delete_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },

    loadData() {
      this.loading = true;
      AdminTemplateTextService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.templateTexts = res.value.templateTexts.items.map((m: TemplateTextIndexDto) => ({
          ...m,
          updatedAt: new Date(m.updatedAt),
        }));
        this.availableTemplateTextTypes = res.value.availableTemplateTextTypes;
        this.templateText.templateTextType = this.availableTemplateTextTypes[0];
        this.pages = res.value.templateTexts.totalPages;
        this.setBreadCrumb();
        this.loading = false;
        this.ready = true;
      });
    },

    handleSubmit() {
      this.loading = true;
      return AdminTemplateTextService.createNew(this.templateText).then(
        (resp) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.$router.push({
            name: 'AdminTemplateTextEdit',
            params: { templateTextId: resp.value },
          });
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },
  },
});
