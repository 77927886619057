var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.ready)?_c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"9"}},[_c('DataTable',{attrs:{"items":_vm.templateTexts,"tableFilter":_vm.tableFilter,"fields":[
          'templateTextType',
          'updatedAt',
          {
            key: 'actions',
            label: '',
            _style: 'width:30%',
            sorter: false,
            filter: false,
          } ],"loading":_vm.loading},scopedSlots:_vm._u([{key:"templateTextType",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.templateTextType)+" ")])]}},{key:"updatedAt",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('FormatedTimestamp',{attrs:{"date":item.updatedAt}})],1)]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary","to":{
                name: 'AdminTemplateTextEdit',
                params: { templateTextId: item.id },
              }}},[_vm._v(" Editieren ")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.onDelete(item.id)}}},[_vm._v("Löschen")])],1)]}}],null,false,1683417620)}),_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1),_c('CCol',{attrs:{"md":3}},[_c('CreateCard',{attrs:{"loading":_vm.loading,"errorMessage":_vm.message,"title":_vm.$t('pages.admin.AdminTemplateTextIndex.createTitle'),"buttonEnabled":!!_vm.templateText.templateTextType},on:{"handleSubmit":_vm.handleSubmit}},[_c('ValidatedSelect',{attrs:{"translationKey":"templateText.templateTextType","field":_vm.$v.templateText.templateTextType,"options":_vm.availableTemplateTextTypeOptions,"value":_vm.$v.templateText.templateTextType.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.templateText.templateTextType, "$model", $event)}}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }